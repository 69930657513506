<!-- 文件中文名: 安置网络图(树形) -->
<template>
  <div class="worksheet-structure app-container">
    <div class="page-container account">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <!--经营权编号-->
          <el-form-item :label="$t('miMember.rightNo')+':'">
            <el-input  @input="value=>listQuery.rightNo=value.toUpperCase()" v-model="listQuery.rightNo" size="mini"/>
          </el-form-item>
          <!--查询层数-->
          <el-form-item :label="$t('miMember.Layer')+(':')">
            <el-select v-model="listQuery.Layer" style="width: 100%" >
              <el-option value="5" label="5" />
              <el-option value="10" label="10" />
              <el-option value="15" label="15" />
              <el-option value="20" label="20" />
              <el-option value="25" label="25" />
              <el-option value="30" label="30" />
              <el-option value="40" label="40" />
              <el-option value="50" label="50" />
              <el-option value="99999" :label="this.$t('Common.ALL')" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <!--搜索-->
            <el-button :loading="loading" icon="el-icon-search" plain type="primary" @click="handleFilter">
              {{ $t('operation.button.search') }}
            </el-button>
          </el-form-item>
          <!--视图模式-->
        </el-form>
        <p v-if="FilterIf" style="font-size: 2.5rem; color: #e9792e; font-weight: 700">
          <i class="el-icon-loading"/>
         {{ this.$t('query_data_wait') }}
        </p>
      </div>
      <el-dialog
          :before-close="handleClose"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          :fullscreen="true"
          :show-close="false"
          :visible.sync="dialogVisible"
      >
        <div>
          <el-input-number
              v-model="num"
              :precision="2"
              :step="0.1"
              :max="2"
              :min="0.1"
              style="position: fixed;top: 20px;left: 40px; z-index: 9999; font-size: 2.5rem;"
          ></el-input-number>
          <el-button
              v-if="!colseIf"
              style="position: fixed;top: 20px;right: 40px; z-index: 9999; font-size: 2.5rem;"
              type="danger"
              icon="el-icon-close"
              circle
              :title="this.$t('operation.button.close')"
              @click="closeBtn"/>
          <el-button
              v-else-if="colseIf"
              style="position: fixed;top: 20px;right: 40px; z-index: 9999; font-size: 2.5rem;"
              type="info"
              icon="el-icon-loading"
              :title="this.$t('close_wait')"
          >
            {{ this.$t('close_wait') }}
          </el-button>
          <div
              :style="transformScale"
          >
            <organization-chart
                v-show="Object.keys(viewData).length"
                v-loading="loading"
                :datasource="viewData"
                :pan="true"
                :zoom="false"
            >
              <template slot-scope="{ nodeData }">
                <div
                    ref="chartRef"
                    v-show="nodeData.memberNo"
                    @click="copyData(nodeData.memberNo)"
                    :class="colorOne[nodeData.levelType ? (nodeData.levelType).substr(0,1) : '']"
                    style="min-width: 150px; border: 1px solid #333333;border-radius: 10px; padding: 6px">
                  <el-popover
                      placement="right-start"
                      width="200"
                      popper-class="pop-div-tree"
                      trigger="click">
                    <div v-loading="popoverLoading" style="text-align: center"
                         :class="colorOne[nodeData.levelType ? (nodeData.levelType).substr(0,1) : '']">
                      <p @click="getClickData(nodeData.memberNo)"
                         style="padding: 2px 0;margin: 2px;border: 2px solid #000">{{ nodeData.memberNo }}</p>
                      <p style="margin: 0">{{ nodeData.name }}</p>
                      <p style="margin: 0">{{ nodeData.memberName }}</p>
                      <div style="margin: 3px 20px">
                        <div v-for="(i,index) in memberNoInfoList" :key="index">
                          <div :class="color[index]" style="margin: 0;padding: 5px">
                            {{ $lt(initDict.mimemberrightleveltype, i.levelType) }} -
                            {{ $t('rpt.memCount') + ':' }}{{ i.personNum }}
                          </div>
                        </div>
                      </div>
                      <p style="margin: 5px">{{ $t('saFiIncomeReport.title.total') + ':' }}{{
                        nodeData.netTotalAmount
                        }}</p>
                      <p style="margin: 5px">{{ $t('busi.poMemberOrder.total.pv') + ':' }}{{ nodeData.netTotalFv }}</p>
                      <p style="margin: 5px">{{ nodeData.registerDate }}</p>
                    </div>
                    <div slot="reference" @click="getMemberNoInfok(nodeData.memberNo)">
                      <p style="margin: 0">{{ nodeData.memberNo }}</p>
                      <p style="margin: 0" v-show="nodeData.levelnum!=='0'">
                        <span style="display: inline-block;width: 50%;text-align: center">{{ nodeData.levelnum }}</span>
                        <span style="display: inline-block;width: 50%;text-align: center">{{ nodeData.leftOrRgiht }}</span>
                      </p>
                    </div>
                  </el-popover>
                </div>
              </template>
            </organization-chart>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

  import {mapGetters} from "vuex"
  import OrganizationChart from 'vue-organization-chart'
  import 'vue-organization-chart/dist/orgchart.css'
  import {miMemberRightViewsTree, miMemberRightViewsTreeData} from "@/api/rus/api";

  export default {
    name: 'WorksheetTree',
    components: {OrganizationChart},
    data() {
      return {
        colseIf: false,
        num: 1,
        FilterIf: false,
        dialogVisible: false,
        popoverLoading: false,
        memberNoInfoList: [],
        colorOne: ['levelType0', 'levelType1', 'levelType2', 'levelType3', 'levelType4'],
        color: ['levelType4', 'levelType3', 'levelType2', 'levelType1', 'levelType0'],
        loading: false,
        viewData: {},
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        listQuery: {
          direction: '1',
          rightNo: '',
          treeOrView: 'Tree',
          Layer: 5,
        },
        initDict: {
          treeorview: 'treeorview',
          yesno: 'yesno',
          mimemberrightleveltype: 'ru.member.level',
        }
      }
    },
    computed: {
      ...mapGetters([
        'isAgent',
        'userCode'
      ]),
      treeorview() {
        return this.$ll(this.initDict.treeorview)
      },
      yesno() {
        return this.$ll(this.initDict.yesno)
      },
      mimemberrightleveltype() {
        return this.$ll(this.initDict.mimemberrightleveltype)
      },
      transformScale() {
        return {
          'width': '100%',
          'height': '100%',
          'transform': 'Scale(' + this.num + ')',
          'transform-origin': '100px 100px',
        }
      }
    },
    created() {
      this.handleFilter()
    },
    mounted() {
      this.listQuery.rightNo = this.userCode
    },
    methods: {
      handleClose(done) { // x关闭按钮
        done()
      },
      // 关闭弹窗
      closeBtn() {
        if (this.listQuery.Layer <= 25) {
          this.colseIf = true
          this.dialogVisible = false
          this.viewData = {}
        } else if (this.listQuery.Layer > 25) {
          this.colseIf = true
          this.dialogVisible = false
          this.viewData = {}
          this.$notify({
            duration: 500,
            title: '',
            message: this.$t('close_wait'),
            type: 'warning'
          })
        }
      },
      getMemberNoInfok(memberNo) {
        this.popoverLoading = true
        this.memberNoInfoList = []
        miMemberRightViewsTreeData({'memberNo': memberNo}).then(res => {
          this.memberNoInfoList = res.data.subordinate
          // for (let a = 0; a < this.memberNoInfoList.length; a++) {
          //   this.memberNoInfoList[a].levelType = this.memberNoInfoList[a].levelType.toString().substr(0, 1)
          // }
          this.popoverLoading = false
        }).catch(() => {
          this.popoverLoading = false
        })
      },
      handleFilter() {
        this.listQuery.rightNo = this.listQuery.rightNo.toUpperCase()
        if (!this.listQuery.rightNo) {
          return false
        }
        this.loading = true
        this.FilterIf = true
        miMemberRightViewsTree({...this.listQuery}).then(res => {
          this.loading = false
          this.viewData = res.data.data
          this.colseIf = false
          this.dialogVisible = true
          this.FilterIf = false
          this.$nextTick(() => {
            document.querySelector('.el-dialog').scrollLeft = document.querySelector('.orgchart-container').offsetWidth / 3
          })
        }).catch(err => {
          this.loading = false
          this.FilterIf = false
          this.viewData = {}
          console.warn(err)
        })
      },
      copyData(rightNo) {
        const input = document.createElement('input');
        input.setAttribute('readonly', 'readonly')
        input.setAttribute('value', rightNo)
        document.body.appendChild(input)
        input.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
          console.log('复制成功')
        }
        document.body.removeChild(input);
      },
      // eslint-disable-next-line no-unused-vars
      getClickData(rightNo) {
        this.loading = true
        miMemberRightViewsTree({...this.listQuery, rightNo: rightNo.toUpperCase()}).then(res => {
          this.loading = false
          this.viewData = res.data.data
        }).catch(err => {
          this.loading = false
          this.viewData = {}
          console.warn(err)
        })
      },
      setAllExpand(state) {
        const nodes = this.$refs.tree.store.nodesMap
        for (const i in nodes) {
          nodes[i].expanded = state
          this.$set(nodes[i], 'expanded', state)
        }
      }
    }
  }

</script>
<style scoped lang="scss">
  .el-popover.pop-div-tree {
    text-align: center;
    background-image: linear-gradient(0deg, #fbf5e4, #eeb9b9);
    padding: 0;
    line-height: normal;
    box-shadow: 0 0 11px 0 rgba(174, 187, 211, 0.24);
    border: solid 1px #9eff00;
  }

  ::v-deep .orgchart {
    width: 100%;
    background-size: 0 0;

    > table {
      margin-top: 100px;
    }

    .node {
      width: 180px;
      margin-right: 10px;

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .orgchart-container {
    min-width: 100%;
    min-height: 600px;
    width: auto;
    height: auto;
    border: 0;
  }

  .Layercss {
    margin-left: 10px;
  }

  .pred {
    color: red;
    display: inline;
  }

  .orgchartnnode p {
    padding: 0;
    margin: 0;
  }

  ::v-deep .mytree {
    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node {
      position: relative;
      padding-left: 16px;
    }

    .el-tree-node__content {
      height: 70px;
    }

    .el-tree-node__expand-icon.is-leaf {
      display: none;
    }

    .el-tree-node__children {
      padding-left: 16px;
    }

    .el-tree-node :last-child:before {
      height: 38px;
    }

    .el-tree > .el-tree-node:before {
      border-left: none;
    }

    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:before {
      border-left: 2px solid #9b9b9b;
      bottom: 0;
      height: 100%;
      top: 0;
      width: 1px;
    }

    .el-tree-node:after {
      border-top: 2px solid #9b9b9b;
      height: 20px;
      top: 37px;
      width: 24px;
    }

    .el-tree > .el-tree-node {
      display: inline-block;
      min-width: 100%;
    }
  }

  .div_in_table {
    th {
      width: 100px;
    }

  }

</style>
<style scoped>
  .orgchartnnode {
    width: 200px;
    min-height: 100px;
    line-height: 20px;
    border-radius: 10px
  }

  .levelType0 {
    background-image: linear-gradient(#ffffff, #f7f7f7);
  }

  .levelType1 {
    background-image: linear-gradient(#cdcdcd, #eaedf1);
  }

  .levelType2 {
    background-image: linear-gradient(#50d2e7, #4dd5ed);
  }

  .levelType3 {
    background-image: linear-gradient(#f1dc99, #e1c063);
  }

  .levelType4 {
    background-image: linear-gradient(#f4748f, #ffa8af);
  }

  /deep/ .orgchart {
    cursor: default;
    transform: matrix(0.8, 0, 0, 0.8, -86, -88);
  }
</style>
